// extracted by mini-css-extract-plugin
export var container = "TopBanner-module--container--99b09";
export var contentContainer = "TopBanner-module--contentContainer--78a8a";
export var icon = "TopBanner-module--icon--18818";
export var iconContainer = "TopBanner-module--iconContainer--a3e9d";
export var text = "TopBanner-module--text--1cc10";
export var textStyleLargeTitle = "TopBanner-module--text-style-large-title--d1d4e";
export var textStyleLargestTitle = "TopBanner-module--text-style-largest-title--445fc";
export var textStyleMediumTitle = "TopBanner-module--text-style-medium-title--1827e";
export var textStyleSmallTitle = "TopBanner-module--text-style-small-title--28104";
export var topBannerContainer = "TopBanner-module--topBannerContainer--8916e";
export var vslPage = "TopBanner-module--vslPage--f2e8c";