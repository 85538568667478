// extracted by mini-css-extract-plugin
export var container = "Timer-module--container--ae787";
export var counter = "Timer-module--counter--d3d77";
export var counterContainer = "Timer-module--counterContainer--cd8e6";
export var counterTitle = "Timer-module--counterTitle--37881";
export var number = "Timer-module--number--0b7b0";
export var text = "Timer-module--text--b38f7";
export var textStyleLargeTitle = "Timer-module--text-style-large-title--6eac7";
export var textStyleLargestTitle = "Timer-module--text-style-largest-title--a184b";
export var textStyleMediumTitle = "Timer-module--text-style-medium-title--ca88b";
export var textStyleSmallTitle = "Timer-module--text-style-small-title--62f4c";
export var timerContainer = "Timer-module--timerContainer--69752";
export var timerFinished = "Timer-module--timerFinished--37675";