import { graphql } from 'gatsby';
import React, { useRef } from 'react';

import { replaceNewLinesWithBr } from '../../utils/sanity';
import { clsx, wrapCurlyBracedInNoBreak, wrapSquareBracketedWithEm } from '../../utils/utils';
import Timer from './Timer';
import VideoHero from './VideoHero';
import * as styles from './VslHero.module.scss';

export const VslHeroFragment = graphql`
  fragment VslHero on SanityLpVslHero {
    timer {
      text
    }
    videoPoster {
      asset {
        url
      }
    }
    videoUrl
    videoFile {
      asset {
        url
      }
    }
    text
    outroText
    buttonText
  }
`;

export interface VslHeroProps {
  timer?: {
    text: string;
  };
  videoPoster?: {
    asset: {
      url: string;
    };
  };
  videoUrl: string;
  videoFile: {
    asset: {
      url: string;
    };
  };
  text: string;
  outroText?: string;
  buttonText: string;
  className?: string;
}

const VslHero = ({
  timer,
  videoPoster,
  videoUrl,
  videoFile,
  text,
  outroText,
  buttonText,
  className,
}: VslHeroProps): React.ReactElement => {
  const heroRef = useRef<HTMLDivElement | null>(null);

  function scrollToHeroBottom() {
    if (heroRef.current) {
      const heroBottomY = heroRef.current.getBoundingClientRect().bottom + window.scrollY;
      window.scrollTo({
        top: heroBottomY,
        behavior: 'smooth',
      });
    }
  }

  return (
    <div className={clsx(className, styles.container)} ref={heroRef}>
      {timer?.text && <Timer text={timer.text} />}
      <div className={styles.heroContainer}>
        <VideoHero
          poster={videoPoster?.asset.url}
          videoUrl={videoFile.asset.url}
          iframeUrl={videoUrl}
        ></VideoHero>
        <div className={styles.textContainer}>
          <h1 className={styles.text}>
            {replaceNewLinesWithBr(text, str =>
              wrapCurlyBracedInNoBreak(str, wrapSquareBracketedWithEm),
            )}
          </h1>
          {outroText && <p className={styles.outroText}>{wrapSquareBracketedWithEm(outroText)}</p>}
          <button className={styles.button} onClick={scrollToHeroBottom}>
            {wrapSquareBracketedWithEm(buttonText)}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VslHero;
