import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { clsx } from '../../utils/utils';

import * as styles from './TopBanner.module.scss';

export interface TopBannerProps {
  className?: string;
  children: any;
  isVslPage?: boolean;
  onClose: () => void;
}

const TopBanner = ({
  className,
  children,
  isVslPage,
  onClose,
}: TopBannerProps): React.ReactElement => {
  return (
    <div className={clsx(className, styles.topBannerContainer, isVslPage && styles.vslPage)}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <p className={styles.text}>{children}</p>
          <div className={styles.iconContainer} onClick={onClose}>
            <FaTimes className={styles.icon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
