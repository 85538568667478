import FsLightbox from 'fslightbox-react';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaPlayCircle } from 'react-icons/fa';
import usePortal from 'react-useportal';

import { clsx } from '../../utils/utils';
import * as styles from './VideoHero.module.scss';

interface VideoHeroProps {
  iframeUrl: string;
  videoUrl: string;
  maxWidth?: number;
  className?: string;
  poster?: string;
}

const VideoHero = ({
  iframeUrl,
  videoUrl,
  className,
  poster,
}: VideoHeroProps): React.ReactElement => {
  const { Portal } = usePortal();

  const [lightboxToggler, setLightboxToggler] = useState(false);

  // TODO WIP
  // https://wistia.com/support/developers/player-api#volume
  const videoRef = useRef(null);

  useEffect(() => {
    // setTimeout(() => {
    window._wq = window._wq || [];
    _wq.push({
      id: 'nk9z4xaf46',
      onReady: function (video) {
        videoRef.current = video;
        // console.log('I got a handle to the video!', video);
        // video.play();
      },
    });
    // }, 3000);
  }, []);

  return (
    <div className={styles.card + ' ' + className}>
      <Helmet>
        <script src="//fast.wistia.net/assets/external/E-v1.js" async></script>
      </Helmet>
      <Portal>
        <FsLightbox
          toggler={lightboxToggler}
          sources={[
            // @ts-ignore
            // TODO WIP
            // <div>
            <iframe
              key="video"
              src={iframeUrl + '?autoPlay=false&playerColor=f43232'}
              title="Hook Point Viral"
              allow="fullscreen"
              allowFullScreen
              frameBorder="0"
              scrolling="no"
              name="wistia_embed"
              className={clsx(styles.iframe, 'wistia_embed')}
            ></iframe>,

            /* TODO WIP */
            /* <button
                onClick={() => {
                  videoRef.current.unmute();
                  videoRef.current.play();
                }}
              >
                XXX
              </button> */
            /* </div>, */
          ]}
        ></FsLightbox>
      </Portal>
      <div
        className={styles.videoContainer}
        onClick={() => {
          setLightboxToggler(!lightboxToggler);
        }}
      >
        <video poster={poster} playsInline autoPlay muted loop className={styles.video}>
          <source src={videoUrl} />
        </video>
        <div className={styles.iconWrapper}>
          <FaPlayCircle className={styles.icon} />
        </div>
      </div>
    </div>
  );
};

export default VideoHero;
