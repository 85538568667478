// extracted by mini-css-extract-plugin
export var card = "VideoHero-module--card--37817";
export var icon = "VideoHero-module--icon--d681d";
export var iconWrapper = "VideoHero-module--iconWrapper--549cb";
export var iframe = "VideoHero-module--iframe--e0180";
export var textStyleLargeTitle = "VideoHero-module--text-style-large-title--b3783";
export var textStyleLargestTitle = "VideoHero-module--text-style-largest-title--c40a3";
export var textStyleMediumTitle = "VideoHero-module--text-style-medium-title--8b8cd";
export var textStyleSmallTitle = "VideoHero-module--text-style-small-title--d1919";
export var video = "VideoHero-module--video--1a6a3";
export var videoContainer = "VideoHero-module--videoContainer--ec8b6";