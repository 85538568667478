import { graphql } from 'gatsby';
import { func } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import { clsx, wrapSquareBracketedWithEm } from '../../utils/utils';

import * as styles from './Timer.module.scss';

export const TimerFragment = graphql`
  fragment Timer on SanityLpViralTimer {
    text
  }
`;

export interface TimerProps {
  text: string;
  className?: string;
}

const INITIAL_TIME_IN_SECONDS = 1799;

const Timer = ({ text, className }: TimerProps): React.ReactElement => {
  const [currentTimeInSeconds, setCurrentTimeInSeconds] = useState<number | null>(null);
  const timerFinished = currentTimeInSeconds === 0;

  useEffect(() => {
    const nowTimestamp = Date.now();
    const firstVisitTimestampStr = window.sessionStorage.getItem('first_visit_timestamp');
    if (!firstVisitTimestampStr) {
      window.sessionStorage.setItem('first_visit_timestamp', nowTimestamp.toString());
      setCurrentTimeInSeconds(INITIAL_TIME_IN_SECONDS);
    } else {
      const firstVisitTimestamp = parseInt(firstVisitTimestampStr);
      const secondsSinceFirstVisit = Math.floor((nowTimestamp - firstVisitTimestamp) / 1000);
      setCurrentTimeInSeconds(Math.max(INITIAL_TIME_IN_SECONDS - secondsSinceFirstVisit, 0));
    }
  }, []);

  useEffect(() => {
    if (currentTimeInSeconds !== null && !timerFinished) {
      setTimeout(() => {
        setCurrentTimeInSeconds(currentTimeInSeconds - 1);
      }, 1000);
    }
  }, [currentTimeInSeconds]);

  if (currentTimeInSeconds === null) {
    return <></>;
  }

  const minutes = Math.floor(currentTimeInSeconds / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (currentTimeInSeconds % 60).toString().padStart(2, '0');

  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.timerContainer}>
        <p className={clsx(styles.text, timerFinished && styles.timerFinished)}>
          {timerFinished ? "Time's up!" : wrapSquareBracketedWithEm(text, replaceNewLinesWithBr)}
        </p>
        <div className={styles.counterContainer}>
          <div className={styles.counter}>
            <span className={styles.number}>00</span>
            <span className={styles.counterTitle}>Days</span>
          </div>
          :
          <div className={styles.counter}>
            <span className={styles.number}>00</span>
            <span className={styles.counterTitle}>Hours</span>
          </div>
          :
          <div className={styles.counter}>
            <span className={styles.number}>{timerFinished ? '00' : minutes}</span>
            <span className={styles.counterTitle}>Mins</span>
          </div>
          :
          <div className={styles.counter}>
            <span className={styles.number}>{timerFinished ? '00' : seconds}</span>
            <span className={styles.counterTitle}>Secs</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timer;
