// extracted by mini-css-extract-plugin
export var button = "VslHero-module--button--58c05";
export var container = "VslHero-module--container--cc2e4";
export var desktopImage = "VslHero-module--desktopImage--08978";
export var heroContainer = "VslHero-module--heroContainer--1db46";
export var mobileImage = "VslHero-module--mobileImage--4bb94";
export var outroText = "VslHero-module--outroText--1fd63";
export var text = "VslHero-module--text--66fdd";
export var textContainer = "VslHero-module--textContainer--840f6";
export var textStyleLargeTitle = "VslHero-module--text-style-large-title--d5603";
export var textStyleLargestTitle = "VslHero-module--text-style-largest-title--6acc9";
export var textStyleMediumTitle = "VslHero-module--text-style-medium-title--39b71";
export var textStyleSmallTitle = "VslHero-module--text-style-small-title--326d9";
export var withNoTimer = "VslHero-module--withNoTimer--cfc20";